.app-header {
    display: flex;
    align-items: center;
    padding: 1rem 2.5rem;

    .logo {
        flex-shrink: 1;
        display: block;
        background-image: url("./assets/images/logo.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 12.5rem;
        height: 4rem;
    }

    .menu {
        flex-grow: 1;
    }

    .user-actions {
        flex-shrink: 1;

        .user-avatar-dropdown {
            transition: background-color .2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
            padding: 0.25rem 0.5rem;
            cursor: pointer;

            &:hover {
                background-color: #353644;
            }

            .avatar {
                border-radius: 50%;
                margin-right: .5rem;
            }

            svg {
                transform: scale(.6);
            }
        }
    }
}
